import React from "react"
import { graphql } from "gatsby"
import * as styles from "../styles/markdown.module.css";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <div className="container mx-auto">
      <article>
        <header>
          <h1 className="text-5xl font-black mt-8 mb-0">
            {frontmatter.title}
          </h1>
        </header>
        <section
          className="markdown"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </article>
    </div>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`
